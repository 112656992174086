<template>
  <div class="flex items-center mt-2">
    <div class="flex items-center w-56 select-none text-sm leading-tight">
      {{ filter.label }}
    </div>
    <div class="w-full">
      <el-switch
        v-model="switchValue"
        :active-text="filter.text.active"
        :inactive-text="filter.text.inactive"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filter: {
      type: Object,
      required: true
    },
    modelValue: {
      type: [Boolean, String],
      required: false,
      default: false
    }
  },
  emits: ['update:modelValue'],
  computed: {
    switchValue: {
      get() {
        if (typeof this.modelValue === 'string') {
          return this.modelValue === 'true'
        }
        return this.modelValue
      },
      set(v) {
        this.$emit('update:modelValue', v)
      }
    }
  }
}
</script>
